import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "page-container"
};
export default {
  __name: 'PageNav',
  props: {
    variant: String
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["page-nav", [__props.variant ? "page-nav_".concat(__props.variant) : '']])
      }, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default")])], 2);
    };
  }
};